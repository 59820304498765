<template>
  <v-card>
    <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        <v-toolbar-title>
          {{ $t("ALIASES.LIST") }}
        </v-toolbar-title>
      </v-tab>
      <v-spacer></v-spacer>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <v-card-text>
            <Table
              :path="path"
              tableId="aliases"
              dbTable="tag_categories"
              :tableInit="tableInit"
              :showParams="showParams"
              :filtersFields="filtersFields"
            ></Table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { TABLE_INIT_URL, GET_ALIASES_INIT } from "@/store/aliases.module";
import Table from "@/views/partials/table/Table.vue";

export default {
  components: {
    Table,
  },
  name: "ShowAliases",
  data() {
    return {
        isProcessing: false,
        path: TABLE_INIT_URL,
        tableInit: GET_ALIASES_INIT,
        tab: "tab-1",
        showParams: { name: "edit-alias", key: "id" },
        filtersFields: [],
    };
  },
  mounted() {
  },
};
</script>

<style>
</style>